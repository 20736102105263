<template>
  <div class="px-10">
    <div class="d-flex align-items-center mb-5">
      <label class="space-nowrap">{{ $t('Language') }}</label>
      <vue-select
        :options="language.options"
        class="input cus-select default-lang"
        v-model="language.selected"
      ></vue-select>
    </div>
    <div class="d-flex align-items-center">
      <v-checkbox 
        color="#0D3856" 
        class="cus-checkbox" 
        v-model="isDefaultLang"
        @change="changeDefaultLang"
        id="default_language"
      ></v-checkbox>
      <label for="default_language" class="px-5">{{ $t('Default Language') }}</label>
    </div>
    <div v-if="language.selected.code=='HE'">
      <label>{{ $t('Additional Detail') }}: {{ $t('Hebrew') }}</label>  
      <vue-editor :value="he_str" class="bg-white mb-10" @input="changeString($event, 'he_str')"></vue-editor>
    </div>
    <div v-if="language.selected.code=='EN'">
      <label>{{ $t('Additional Detail') }}: {{ $t('English') }}</label>  
      <vue-editor :value="en_str" class="bg-white mb-10" @input="changeString($event, 'en_str')"></vue-editor>
    </div>
    <div v-if="language.selected.code=='AR'">
      <label>{{ $t('Additional Detail') }}: {{ $t('Arabic') }}</label>  
      <vue-editor :value="ar_str" class="bg-white mb-10" @input="changeString($event, 'ar_str')"></vue-editor>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiLangRichText",
  props: {
    ar_str: {},
    en_str: {},
    he_str: {},
    default_language: {},
  },
  data() {
    return {
      language: {
        options: [
          {
            code: 'HE',
            label: 'עברית'
          },
          {
            code: 'AR',
            label: 'العربية'
          },
          {
            code: 'EN',
            label: 'English'
          },
        ],
        selected: {
          code: 'HE',
          label: 'עברית'
        }
      }
    }
  },
  computed: {
    isDefaultLang: {
      get(){
        return this.default_language == this.language.selected.code;
      },
      set(){
        return this.default_language == this.language.selected.code;
      } 
    },
  },
  methods: {
    changeDefaultLang() {
      this.default_language = this.language.selected.code;
      this.$emit("update:default_language", this.default_language);
    },
    changeString(e, str) {
      const event_key = "update:" + str;
      this.$emit(event_key, e);
    }
  }
}
</script>

<style lang="scss" scoped>
  .default-lang {
    width: 140px;
    margin: 0 20px;
  }
</style>
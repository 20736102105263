<template>
  <main class="page_bg_grey main-content blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="setting-title">{{ $t("Tourism Settings") }}</p>
    <div class="setting-content">
      <div class="setting-item">
        <label class="setting-label" ref="longLabel">{{ $t("Offer replacement packages when the price is expensive up to") }}</label>
        <div class="d-flex align-items-center">
          <input type="text" class="input" v-model="setting_data.alternative_deal">
          <p class="setting-unit">{{ $t("Percent") }}</p>
        </div>
      </div>
      <div class="setting-item">
        <label class="setting-label">{{ $t("Percentage absorption price increase for cash purchases") }}</label>
        <div class="d-flex align-items-center">
          <input type="text" class="input" v-model="setting_data.cash_price_up_max">
          <p class="setting-unit">{{ $t("Percent") }}</p>
        </div>
      </div>
      <div class="setting-item">
        <label class="setting-label">{{ $t("Number of days a marketer is eligible for a commission") }}</label>
          <div class="d-flex align-items-center">
          <input type="text" class="input" v-model="setting_data.days_allow_for_fees">
        <p class="setting-unit">{{ $t("Days") }}</p>
        </div>
      </div>
      <div class="setting-item">
        <label class="setting-label">{{ $t("Minimum profit percentage for Plators") }}</label>
          <div class="d-flex align-items-center">
          <input type="text" class="input" v-model="setting_data.minimum_peletours_fee">
        <p class="setting-unit">{{ $t("Percent") }}</p>
        </div>
      </div>
      <div class="setting-item">
        <label class="setting-label">{{ $t("Adding pennies to a currency exchange fee") }}</label>
          <div class="d-flex align-items-center">
          <input type="text" class="input" v-model="setting_data.add_cents_to_currency">
        <p class="setting-unit">{{ $t("Pennies") }}</p>
        </div>
      </div>
      <div class="setting-item">
        <label class="space-nowrap">{{ $t('Passport') }}</label>
        <vue-select
          :options=passportOpt
          class="input cus-select default-lang mrz-select"
          @input="changePassport()"
          v-model="passportModel"
        ></vue-select>
      </div>

      <hr class="block-hr">
      <label for=""><b>Nofshon Cancellation</b></label>
      <MultiLangRichText
        class="rich-text" 
        :ar_str.sync="setting_data.nofshon_cancellation_details_ar" 
        :en_str.sync="setting_data.nofshon_cancellation_details_en" 
        :he_str.sync="setting_data.nofshon_cancellation_details_he" 
        :default_language.sync="setting_data.nofshon_cancellation_details_default_language" 
      ></MultiLangRichText>

      <hr class="block-hr">
      <label for=""><b>Nofshon General Rules</b></label>
      <MultiLangRichText
        class="rich-text" 
        :ar_str.sync="setting_data.nofshon_general_rules_ar" 
        :en_str.sync="setting_data.nofshon_general_rules_en" 
        :he_str.sync="setting_data.nofshon_general_rules_he" 
        :default_language.sync="setting_data.nofshon_general_rules_default_language" 
      ></MultiLangRichText>
      
      <hr class="block-hr">
      <label for=""><b>Organized Cancellation Details</b></label>
      <MultiLangRichText
        class="rich-text" 
        :ar_str.sync="setting_data.organized_cancellation_details_ar" 
        :en_str.sync="setting_data.organized_cancellation_details_en" 
        :he_str.sync="setting_data.organized_cancellation_details_he" 
        :default_language.sync="setting_data.organized_cancellation_details_default_language" 
      ></MultiLangRichText>

      <hr class="block-hr">
      <label for=""><b>Organized General Rules</b></label>
      <MultiLangRichText
        class="rich-text" 
        :ar_str.sync="setting_data.organized_general_rules_ar" 
        :en_str.sync="setting_data.organized_general_rules_en" 
        :he_str.sync="setting_data.organized_general_rules_he" 
        :default_language.sync="setting_data.organized_general_rules_default_language" 
      ></MultiLangRichText>
    </div>
    <a class="link category__button total-save-btn text-center color-white mb-15" @click="update_generalSetting">{{ $t("Save") }}</a>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import MultiLangRichText from '../../../components/management/MultiLangRichText.vue';
import { settingService } from '../../../services/setting.service';

export default {
  name: "GeneralSetting",
  components: {
    Loading,
    MultiLangRichText 
  },
  computed: {
    ...mapState({
      setting_data: state => state.setting.general_setting,
      loading: state => state.setting.loading
    })
  },
  async created() {
    if(this.setting_data == "") {
      this.get_generalSetting();
    }
    settingService.getPassportSelect().then(r => {
      if(!r.data.error && r.data.content){
        if(r.data.content.id == '1'){
          this.passportModel.code = '1'
          this.passportModel.label = this.$t('Acurra')
        } else {
          this.passportModel.code = '2'
          this.passportModel.label = this.$t("mrz scanner")
        }
      }
    })
  },
  data() {
    return {
      passportOpt: [
          {
            code: '1',
            label: this.$t('Acurra')
          },
          {
            code: '2',
            label: this.$t("mrz scanner")
          }
      ],
      passportModel: {
        code: '',
        label: ''
      }
    };
  },
  methods: {
    ...mapActions("setting", {
      get_generalSetting: "get_generalSetting",
      update_generalSetting: "update_generalSetting"
    }),
    ...mapActions("alert", {
      errorAlert: "error",
      successAlert: "success"
    }),
    async updateSettings() {
      await this.update_generalSetting();
      this.get_generalSetting();
    },
    async changePassport() {
      let params = {
        id: this.passportModel.code
      } 
      settingService.updatePassportSelect(params).then(r => {
        if(!r.data.error){
          this.successAlert('Successfully updated')
        } else {
          this.errorAlert('Error occured');
        }
      }).catch(e => {
        this.errorAlert('Error occured')
      })
    }
  }
};
</script>

<style lang="scss" scoped>
  .setting-title {
    font-size: 26px;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
  }
  .setting-content {
    margin: 30px 30px 50px;
    .setting-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      label {
        margin-right: 15px;
        width: 520px;
      }
      .input {
        width: 75px;
        margin: 0 15px;
      }
    }
  }
  .rich-text {
    max-width: 900px;
  }

  .block-hr {
    border-top: 1px;
    margin-bottom: 20px;
    max-width: 1100px;
  }
  .mrz-select {
    width: 150px !important;
  }
  @media screen and (max-width: 770px){
    .setting-content {
      .setting-item {
        label {
          width: 60vw;
        }
      }
    }
  }
  @media screen and (max-width: 450px){
    .setting-content {
      .setting-item {
        flex-wrap: wrap;
        label {
          margin-bottom: 10px;
        }
      }
    }
  }
</style>